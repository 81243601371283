import React from 'react';
import { Link } from 'react-router-dom';

export function LAGPrivacyPolicyEn() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>Sorstalpaló</h1>
        <h2>Privacy policy</h2>
        <p>We do not disclose any data to third parties.</p>
        <h3>Personal data</h3>
        <p>We do not store any personal information.</p>
        <h3>Player activities</h3>
        <p>
          We store alabies for game usage analyzes for a short time - up to 1 week in a non-personal way:
          <ul>
            <li>gameplay - the player's decisions</li>
          </ul>
        </p>
        <p>
          To improve user support, we store the following tied to the player ID:
          <ul>
            <li>in-game transactions</li>
          </ul>
        </p>
        <p>
          <Link to='/adatvedelmi-iranyelvek'>Magyar</Link>
        </p>
      </div>
    </div>
  );
}
