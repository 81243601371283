import React from 'react';
import { Link } from 'react-router-dom';

export function LAGOwnAdventures() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>Élő kalandok</h1>
        <h2>Lehet saját kalandom!</h2>
        <p>
          Az <i>Élő kalandok</i> rendszere úgy lett felépítve, hogy a webes kalandszerkesztő alkalmazás segítségével bárki képes kalandokat
          létrehozni.
        </p>
        <p>
          Azt szeretnénk, hogy az alkotás iránti vágy, a kreativitás szabadon áramolhasson, és egymást inspirálva egy minél színesebb
          kalandgyűjteményt gazdagíthassunk, és gyermeki izgalommal próbálhassuk ki egymás alkotását.
        </p>
        <p>
          A kellemes mellé hasznos oldal is társul, ugyanis ezek a kalandok olyan célokat is szolgálhatnak, mint az ismeretterjesztés,
          figyelemfelhívás például környezetvédelmi témákban, az önismeret, szociális érzékenyítés, oktatás, turizmus, és még sorolhatnánk.
        </p>
        <p>Mindezek mellett együttműködésre is folyamatosan nyitottak vagyunk, legyen szó akár a te projektedről, vagy a mieinkről.</p>
        <p>
          Vedd fel velünk a kapcsolatot, ha szeretnéd kipróbálni a kalandkészítést, vagy bármilyen formában együttműködnél, vagy ha bármilyen kérdésed
          van!
        </p>
        <p>
          <a href='mailto:sziebert.au@gmail.com'>Kapcsolat</a>
          <br />
          <a href='https://play.google.com/store/apps/details?id=hu.auapp.liveadventures'>Android alaklmazás</a>
          {/* <br /> */}
          {/* <Link to='/szerkeszto-adatvedelmi-iranyelvek'>Adatvédelmi irányelvek</Link> */}
        </p>
        <p />
      </div>
    </div>
  );
}
