import React from 'react';
import { Link } from 'react-router-dom';

export function LAGCreative() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>
          <img src='/app icon 100.png' style={{ marginRight: '0.5em' }} />Kreatív Sorstalpalók
        </h1>

        <p>Művészetterápiás csoport.</p>
        <p>Mindannyiunkban ott dolgozik a mélyben, ez nem vitás. Sikerült már hozzáférned? Próbáltad már kiadni magadból? Ha igen, ha nem, itt a lehetőség!</p>

        <h2>Miről beszélek?</h2>
        <p>Nekem nagyon jól bevált, hogy a nehézségeimet, felismeréseimet, örömeimet egy adott keretrendszeren belül megfogalmazva, a valóságot a fantázia szintjére manifesztálva adom ki magamból. Szinte észre sem veszem, és a legnehezebben megfogalmazható érzéseim a papírról figyelnek vissza rám. </p>
        <p>A cél itt nem az, hogy elbagatellizáljuk érzéseinket, vagy lehasítsuk magunkról. A mesevilág, amiben írunk, egy művészetterápiás eszköz arra, hogy érzéseinket, traumáinkat, elnyomott, elfeledett részeinket beazonosíthassuk, megfogalmazhassuk, kiadhassuk magunkból, és ezáltal közelebb kerüljünk hozzájuk.</p>
        <p>Arra invitállak, hogy nézz szét az itt szereplő írások között, ismerkedj meg a Sorstalpaló játékkal, és engedd, hogy az inspiráció, a közös kreatív munkára való vágy elragadjon. </p>

        <h2>Mi a Sorstalpaló?</h2>
        <p>Interaktív, a valós térben zajlódó, mobil vezérlésű kalandjáték. Természetjárás, fantázia, geocaching, önismeret.</p>
        <p>
          <Link to='/'>Bővebb információ</Link>
        </p>

        <h2>Mit céloz ez a kreatív csoport?</h2>
        <p>A művészetterápia eszközeivel kerülünk közelebb magunkhoz és egymáshoz. Erőt, bíztatást adunk egymásnak, kreatív energiát áramoltatunk. Első körben az írás eszközét alkalmazva tesszük mindezt.</p>

        <h2>Hogyan írj?</h2>
        <p>Annak érdekében, hogy ne vessz el a végtelen lehetőségek tengerében, adok egy irányt, egy alap ötletet, egy keretet az alkotásra. Vannak különféle készülő kalandok, ezek interaktív mesék lesznek, amiket a játékosok játszani fognak majd.</p>
        <p>Nézd meg, mi az, ami a legerősebben dolgozik benned ebben a pillanatban, keresd meg az esszenciáját - mi ez, szomorúság, öröm? -, majd manifesztáld bele a mesébe. Milyen karakter, helyszín, szituáció, jelenet jut eszedbe? A fantázia világában bármi megtörténhet, ne fogd vissza magad! Lehet bármilyen apró vízió, ragadd meg és indulj el rajta! Ne legyen semmiféle elvárásod! Próbálj nem viszonyulni semmilyen módon ahhoz, ami most kicsúszik belőled. Ne alkoss véleményt, ne értékeld, maradj független. Az irományod lehet bármilyen rövid vagy hosszú, bármilyen stílusú, idejű, szemszögű. </p>
        <p>Végső soron ne ragaszkodj semmihez. Az általam szolgáltatott keret - az alapötlet, a világ, a mese vázlat cselekménye - csak egy segítséget próbál nyújtani, de nem kötelező használnod. Az is tökéletesen jó, ha például csak egy nagyon absztrakt érzés fogalmazódik meg. Szólhat akár ez az írás a vizuális formák, színek vagy hangok játékáról is.</p>
        <p>Arra hívlak, hogy légy bátor! Végy nagy levegőt, és oszd meg azt, ami benned van. Ne szégyelld, próbáld szeretni! Meglátod, hogy nincs olyan, hogy elfogadhatatlan, nincs olyan, hogy ciki, és nincs olyan, hogy bármivel is egyedül lennél. A megosztásnak az a célja, hogy észrevegyük, mind ugyanazokkal a problémákkal, nehézségekkel küszködünk. Ha ezt ily módon sikerül felfedeznünk, azzal nem csak hogy magunkhoz kerülhetünk közelebb, de egymáshoz is, ezáltal csökkenhet a korunkra jellemző elszigeteltség. Társas és érző lények vagyunk, elemi szükségletünk az érzések szabad megélése, és ezek közösségben való elfogadottságának megélése.</p>
        <p>Mindeközben figyelj arra, hogy csak annyit ossz meg magadból, amennyit jól esik, amennyi a felszabadulás érzését adja neked vissza. Ez a csoport publikus abból a célból, hogy másokat is inspirálhassunk az önismeretre és az önfeltárásra. Ha úgy érzed, nem állsz készen a megosztásra, tartsd meg az írásod, vagy mutasd meg csak egy kisebb körben, vagy csak a legbizalmasabb barátodnak. </p>

        <h2>Hogyan segíthet ez rajtam?</h2>
        <p>Miután úgy érzed, sikerült valamit - netalán mindent - papírra vetned, ami éppen benned dolgozik, akkor ideje megnézni, hogy mi az. Alkotás közben próbáltunk függetlenek lenni, hogy szabadon áramolhasson kifelé az, ami akar, hogy ne torzítsuk, ne akasszuk el a folyamot. De a célunk nem az, hogy az érzéseinket levágjuk, lecsatoljuk magunkról, hanem épp ellenkezőleg. Eljött tehát az idő, hogy alaposan megvizsgáljuk, hogy mi az, ami kijött. Olvassuk el figyelmesen, és próbáljuk beleérezni magunkat. Keressük meg, hogy hol vagyunk benne, mit árul el rólunk. Ez a megfogalmazott valami hogyan befolyásolja az életemet, a mindennapi működésemet. Milyen esetleges feldolgoznivalóra hívja fel a figyelmet? Szolgálhat ez az írás témaként a következő terápiás ülésedre.</p>

        <h2>Hogyan áll össze a kép?</h2>
        <p>Kis puzzle darabkákat alkotunk meg, független kis történetdarabkákat. A lehető legőszintébben, leghűbben. Ezek a darabkák későbbi engedélyezés, egyeztetés és feldolgozási munka során egy egységes mesébe lesznek gyúrva, amik egy interaktív játék formáját fogják ölteni a Sorstalpaló alkalmazásban. </p>
        <p>Így fogunk egy közös szerelemgyermeket kapni, a közös munkánk gyümölcseként egy olyan történetet, ami rólunk szól, ami őszinte, hiteles, valódi, emberi.</p>

        <h2>Hogyan segíthet ez másokon?</h2>
        <p>A történet, a játék, amit más emberek játszhatnak, így épül fel életünk legfontosabb aspektusaiból: csalódásokból, fájdalmakból, fejlődésekből, győzelmekből, felszabadulásból, boldogságból. Azok az embertársaink, akik esetleg még nem férnek hozzá, nem ismerték fel azokat a traumáikat, amik akár tudtuk nélkül hátráltatja őket a boldog és felszabadult élet kialakításában, ezeket a kalandokat játszva felismeréseket nyerhetnek, apró kis magocskákat ültethetünk az elméjük mélyén, amik a megfelelő időben szárba szökkenhetnek majd.</p>
        <p>A varázslat, ami a kalandok létrejöttét lehetővé teszi, az önfeltárás, önfelvállalás, az empátia, az elfogadás és a szeretet elegye. Hiszem, hogy ennek a varázsnak akkora ereje van, hogy a jövőbeni játékosokra olyan hatást fog gyakorolni, olyan benyomást fog tenni, ami számít, ami elegendő üzemanyagot szolgáltathat nekik egy következő varázslatos folyamat elindulásában. Mindezért azt gondolom, nincs az a kemény és bátor munka, amit ne érné meg megtenni.</p>

        <h2>Mi nem</h2>

        <p>Ha szeretnéd, vedd fel velem a kapcsolatot, nagy örömömre szolgálna hallani felőled és arról, benned mindez mit indít el! <br /><a href="mailto:sziebert.au@gmail.com">sziebert.au@gmail.com</a></p>
      </div>
    </div>
  );
}
