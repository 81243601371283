import React from 'react';
import { Home } from './containers/Home';
import LAGRoutesApp from './containers/lag/LAGRoutes';

function App() {
  if (window.location.href.includes('://elokalandok.auapp.hu') || window.location.href.includes('://sorstalpalo.auapp.hu')) {
    // if (true) {
    return <LAGRoutesApp />;
  } else {
    return <Home />;
  }
}

export default App;
