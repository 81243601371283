import React from 'react';
import { Link } from 'react-router-dom';

export function LAGDataDeletion() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>Sorstalpaló</h1>
        <h2>Adatok törlése</h2>
        <p>Személyes adatainak törlésére vonatkozó igényét kérjük jelezze a sorstalpalo@auapp.hu email címre küldött levelében.</p>
      </div>
    </div>
  );
}
