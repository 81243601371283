import React from 'react';
import { Link } from 'react-router-dom';

export function LAGHome() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>
          <img src='/app icon 100.png' style={{ marginRight: '0.5em' }} />Sorstalpaló
        </h1>
        <p>Interaktív, a valós térben zajlódó, mobil vezérlésű kalandjáték. Természetjárás, fantázia, geocaching, önismeret.</p>

        <h2>Hogyan működik?</h2>
        <p>
          A mobilod a kalandmestered, történetet mesél el, karakterekkel beszélgethetsz, küldetések mentén valós helyszíneken fizikailag mozogva a
          fantázia világában élhetsz meg valós élményeket, amik belőled fakadnak.
        </p>
        <h2>Hol próbálhatod ki?</h2>
        <p>
          Jelenleg egy kaland készült el, aminek a Krisna-völgyi Indiai Kulturális Központ ad helyszínt. Egy szórakoztató, interaktív bevezetést
          hivatott nyújtani a vendégek részére mind helyi, kulturális és filozófiai téren az ott megrendezésre kerülő fesztiválok alkalmára - de
          egyébként bármikor játszható.
        </p>
        <h2>Hogyan működik technikailag?</h2>
        <p>
          Ez egy olyan rendszer, aminek webes szerkesztőjével mobilos platformra bármilyen jellegű, tartalmú, hangulatú, mondanivalójú interaktív
          kaland megalkotható bármilyen helyszínre, legyen az egy városi park, egy erdő, vagy akár egy falu.
        </p>
        <h2>Együttműködés</h2>
        <p>
          Az álommunkahelyem nem egy egyszemélyes hadsereg. Az a vágyam, hogy azonos értékeken és érzéseken osztozva, belső motivációnknak engedve
          együtt munkálkodjunk céljaink elérésén a szív útján. Szeretném, hogy szárnyaljon a kreatív energia. Nem titkolt célom ezzel a projekttel
          többek között a kapcsolatteremtés.
        </p>
        <h3>Milyen lehetőségek nyílnak alkotóként?</h3>
        <p>
          A cél a kreatív fantázia eszközeivel jelentőségteljes élményeket nyújtani. Utak széles palettája nyílik átadni bármit, ami belül lappang,
          mocorog vagy tombol.
        </p>
        <p>
          Ha bármi kérdés felmerült benned, bármilyen szinten hívást érzel akár csak egy beszélgetésre, vagy bármilyen szintű együttműködésre, öröm
          lenne számomra, ha írnál.
        </p>
        <p>
          Ezeken a területeken tud a projekt szeretetteljes törődést befogadni:
          <ul>
            <li>grafika: UI, és "kaland art": festés akár fizikai, akár digitális formában,</li>
            <li>írás: hiteles karakterek megalkotása, erőteljes hangulatleíró elbeszélések, dialógusok, </li>
            <li>kalandmester: izgalmas, interaktív szerepjátékos szerkezet (küldetések, rejtvények, meglepetések, fordulatok, humor),</li>

            <li>hangeffektek, </li>
            <li>
              szoftver: az ötletek, tervek végtelenek arra nézve, hogyan lehetne élvezhetőbbé, hatékonyabbá, jobbá tenni a rendszert, szorgosan gépelő
              ujjaim száma viszont véges. Alkalmazott technológiák: Unity, C#, React, AWS, NodeJs, Typescript.
            </li>
            <li>pszichológia/coaching: hogyan lehet a lehető legkomolyabb önismereti eszközzé tenni ezt a játékot.</li>
          </ul>
        </p>
        <p>
          A fent említetteket mind én viszem jelenleg, de professzionálisan csak a szoftver fejlesztéshez értek, így bármilyen szaksegítség, akárcsak
          szakvélemény minőségi javulást hozhat a többi témakörben.
        </p>
        <p>
          A közreműködésért a kapcsolódás örömén túl jelenleg a vállalkozásod promótálását tudom felajánlani - amennyiben kapcsolódik az adott kaland
          témájához, értékes közvetítést kaphatsz releváns felhasználókhoz.
        </p>
        <h2>Aktuális kaland projekt</h2>
        <h3>Szabadságsziget</h3>
        <p>
          "A Margit-sziget elszabadult! Segíts az öntelt Kapitánynak, hogy a szigetet nyugalomra kormányozhassa! A kulcs lehet, hogy a sötétebbnél is
          sötétebb Romvárosban van, de ne félj, a Bölcsek a segítségedre lesznek, hogy sikerrel vedd a kihívásokat!"
        </p>
        <p>
          Önismereti kalandjáték traumákról, önmegnyugtatásról.<br />Meg tudod váltani a saját szabadságod?
        </p>
        <div className='store-badges'>
          <a href='https://play.google.com/store/apps/details?id=hu.auapp.liveadventures'>
            <img alt='Szerezd meg: Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/hu_badge_web_generic.png' />
          </a>
          <a href='https://apps.apple.com/us/app/sorstalpal%C3%B3/id1624731135'>
            <img alt='Letölthető az App Store-ból' src='/Download_on_the_App_Store_Badge_HU_RGB_blk_100317.svg' />
          </a>
        </div>
        {/* <p>
          <Link to='/sajat-kalandok'>Saját, egyedi kalandok</Link>
        </p> */}
        <p>
          <Link to='/adatvedelmi-iranyelvek'>Adatvédelmi irányelvek</Link>
        </p>
      </div>
    </div>
  );
}
