import React from 'react';
import '../styles/Home.css';

export function Home() {
  return (
    <div className='home'>
      <img id='site-logo' src='/logo-white.png' alt='Auapp logo' />
      <div className='app-container'>
        <a href='https://sorstalpalo.auapp.hu' className='lag-link'>
          <div className='title'>
            <img src='/app icon 100 white.png' />Sorstalpaló
          </div>
          <div className='short-description'>Mobil vezérelt, valós térben, több helyszínen zajlódó kalandjáték</div>
        </a>
        <a href='https://identityjatek.hu/' className='identity-link'>
          <img src='/logoDropShadow.png' alt='Identity logo' />
          <div className='short-description'>Pszichológiai társasjáték a mobilodon</div>
        </a>
      </div>
    </div>
  );
}
