import React from 'react';

export function LAGPrivacyPolicyDesigner() {
  return (
    <div className='lag'>
      <div className='content'>
        <h1>Élő kalandok szerkesztő</h1>
        <h2>Adatvédelmi irányelvek</h2>
        <p>
          A kalandok fejlesztői változatainak lejátszása során a játékos tevékenységéről adatokat gyűjtünk, és rövid ideig megőrizzük, valamint a
          szerkesztőben hibakeresési célokra a kalandot szerkesztő személy rendelkezésére bocsátjuk.
        </p>
      </div>
    </div>
  );
}
