import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { LAGHome } from './LAGHome';
import { LAGPrivacyPolicy } from './LAGPrivacyPolicyApp';
import { LAGPrivacyPolicyEn } from './LAGPrivacyPolicyEn';
import '../../styles/lag.css';
import { LAGOwnAdventures } from './LAGOwnAdventures';
import { LAGPrivacyPolicyDesigner } from './LAGPrivacyPolicyDesigner';
import { LAGCreative } from './LAGCreative';
import { LAGDataDeletion } from './LAGDataDeletion';

function LAGRoutes() {
  return (
    <Routes>
      <Route path='/' element={<LAGHome />} />
      <Route path='/:adventureId' element={<LAGHome />} />
      <Route path='/kreativ' element={<LAGCreative />} />
      <Route path='/adatvedelmi-iranyelvek' element={<LAGPrivacyPolicy />} />
      <Route path='/adatok-torlese' element={<LAGDataDeletion />} />
      <Route path='/privacy-policy' element={<LAGPrivacyPolicyEn />} />
      <Route path='/szerkeszto-adatvedelmi-iranyelvek' element={<LAGPrivacyPolicyDesigner />} />
      <Route path='/sajat-kalandok' element={<LAGOwnAdventures />} />
      {/* <Route path='/adventure-designer/:id/:versionId' element={<AdventureDesignerWrapper />} /> */}
    </Routes>
  );
}

function LAGRoutesApp() {
  return (
    <Router>
      <LAGRoutes />
    </Router>
  );
}

export default LAGRoutesApp;
